import { useRouter } from 'next/router'
import { useLayoutEffect } from 'react'

/**
 * 현재 게시되고 있는 핵심 이벤트로 랜딩되로록함
 */
export default function IndexPage() {
  const { replace } = useRouter()

  useLayoutEffect(() => {
    replace('/ext/ktostay/nation/MrTjGHYpKe')
  }, [replace])

  return null
}
